import { DATE_TIME_FORMAT } from 'utils/DateFormats';
import moment from 'moment';
import LocalStorageService from 'services/LocalStorageService';

export const getPermissions = (userData = null) => {
  if (!userData) userData = LocalStorageService.getUserData();
  const { station, permissions } = userData;

  const servicesAllowed =
    anyMatch(['ARN-ERSR', 'ARN-EPSR', 'ARN-EOSR', 'ARN-ELSR'], permissions, station);
  const timeStampsAllowed =
    anyMatch(['ARN-ERTR', 'ARN-EPTR', 'ARN-EOTR', 'ARN-ELTR', 'ARN-EBTR'], permissions, station);
  const photosAllowed =
    anyMatch(['ARN-TRIP', 'ARN-TPIP', 'ARN-TOIP', 'ARN-TLIP'], permissions, station);
  const supervisorAllowed = (permissions || '').includes(
    `${station}-${'ARN-MSRC'}`
  );

  const psmTelexAllowed = (userData.permissions || '').includes(
    `${station}-${'ARN-VT-PSM'}`
  );
  const ptmTelexAllowed = (userData.permissions || '').includes(
    `${station}-${'ARN-VT-PTM'}`
  );
  const lpmTelexAllowed = (userData.permissions || '').includes(
    `${station}-${'ARN-VT-LPM'}`
  );
  const lirTelexAllowed = (userData.permissions || '').includes(
    `${station}-${'ARN-VT-LIR'}`
  );
  const remarkAllowed = (userData.permissions || '').includes(
    `${station}-${'ARN-EFR'}`
  );


  const regOpsAllowed = (userData.permissions || '').includes(
    `${station}-${'ARN-EOSR'}`
  );
  const viewOpsAllowed = regOpsAllowed;

  const regPaxAllowed = (userData.permissions || '').includes(
    `${station}-${'ARN-EPSR'}`
  );
  const viewPaxAllowed = regPaxAllowed;

  const regRampAllowed = (userData.permissions || '').includes(
    `${station}-${'ARN-ERSR'}`
  );
  const viewRampAllowed = regRampAllowed;

  const closureFlightAllowed = (userData.permissions || '').includes(
    `${station}-${'ARN-CFLT'}`
  );
  const reopenFlightAllowed = (userData.permissions || '').includes(
    `${station}-${'ARN-RFLT'}`
  );

  const ffiTelexAllowed = (userData.permissions || '').includes(
    `${station}-${'ARN-VT-FFI'}`
  );
  const clsTelexAllowed = (userData.permissions || '').includes(
    `${station}-${'ARN-VT-CLS'}`
  );
  const uwsTelexAllowed = (userData.permissions || '').includes(
    `${station}-${'ARN-VT-UWS'}`
  );
  const xryTelexAllowed = (userData.permissions || '').includes(
    `${station}-${'ARN-VT-XRY'}`
  );
  const figsTelexAllowed = (userData.permissions || '').includes(
    `${station}-${'ARN-VT-FIGS'}`
  );
  const inspectionAllowed = (userData.permissions || '').includes(
    `${station}-${'ARN-VFI'}`
  );
  const loadVerificationAllowed = (userData.permissions || '').includes(
    `${station}-${'ARN-RLVC'}`
  );
  const walkaroundChecklistAllowed = (userData.permissions || '').includes(
    `${station}-${'ARN-MWC'}`
  );
  const holdInspectionAllowed = (userData.permissions || '').includes(
    `${station}-${'ARN-MHI'}`
  );
  const deicingChecklistAllowed = (userData.permissions || '').includes(
    `${station}-${'ARN-MDR'}`
  );
  const deicingInspectionAllowed = (userData.permissions || '').includes(
    `${station}-${'ARN-VDAII'}`
  );
  const boardingInspectionAllowed = (userData.permissions || '').includes(
    `${station}-${'ARN-VBI'}`
  );
  const checkInInspectionAllowed = (userData.permissions || '').includes(
    `${station}-${'ARN-VCII'}`
  );
  const airsideEnvInspectionAllowed = (userData.permissions || '').includes(
    `${station}-${'ARN-VEIA'}`
  );
  const officeEnvInspectionAllowed = (userData.permissions || '').includes(
    `${station}-${'ARN-VEIO'}`
  );
  return {
    ffiTelexAllowed,
    clsTelexAllowed,
    uwsTelexAllowed,
    xryTelexAllowed,
    figsTelexAllowed,
    servicesAllowed,
    timeStampsAllowed,
    supervisorAllowed,
    photosAllowed,
    remarkAllowed,
    lirTelexAllowed,
    psmTelexAllowed,
    viewPaxAllowed,
    regPaxAllowed,
    viewOpsAllowed,
    viewRampAllowed,
    regRampAllowed,
    lpmTelexAllowed,
    ptmTelexAllowed,
    closureFlightAllowed,
    reopenFlightAllowed,
    inspectionAllowed,
    loadVerificationAllowed,
    walkaroundChecklistAllowed,
    holdInspectionAllowed,
    deicingChecklistAllowed,
    deicingInspectionAllowed,
    boardingInspectionAllowed,
    checkInInspectionAllowed,
    airsideEnvInspectionAllowed,
    officeEnvInspectionAllowed,
  };
};

function anyMatch(features, permissions, station) {
  return features
    .map(feature => `${station}-${feature}`)
    .reduce((allowed, feature) =>
      allowed || (permissions || '').includes(feature),
      false
    );
}

export const isNotActualCache = (lastFetchDate, checkIntervalMinutes = 0) => {
  const updateOffset = moment().subtract(checkIntervalMinutes, 'minutes');

  return !moment(lastFetchDate, DATE_TIME_FORMAT).isBetween(
    updateOffset,
    moment(),
    null,
    '[]'
  );
};

export const generateId = () =>
  new Date().valueOf().toString(36) +
  Math.random()
    .toString(36)
    .substr(2);

export const matchFlightInfo = (fbInfo, nhsInfo) => {
  const noFbData = fbInfo && !fbInfo.flightId;
  const noNhsInfo = nhsInfo && !nhsInfo.flightId;
  let resultInfo = null;
  if (noFbData && !noNhsInfo) resultInfo = nhsInfo;
  if (noNhsInfo && !noFbData) resultInfo = fbInfo;
  if (!noNhsInfo && !noFbData) resultInfo = nhsInfo;
  if (resultInfo) resultInfo.msrCompleted = fbInfo.msrCompleted;
  return resultInfo;
};
export const getFlightTypeFormat = value => {
  const flightTypesMask = {
    arrivals: 'arrival',
    departures: 'departure',
    0: 'arrivals',
    1: 'departures',
    A: 'arrivals',
    D: 'departures',
    arrival: 'arrivals',
    departure: 'departures',
  };
  return flightTypesMask[value];
};

function baseAssignValue(object, key, value) {
  if (key === '__proto__') {
    Object.defineProperty(object, key, {
      configurable: true,
      enumerable: true,
      value: value,
      writable: true,
    });
  } else {
    object[key] = value;
  }
}

export const groupBy = (collection, iteratee) => {
  return collection.reduce((acc, item, index) => {
    index = iteratee(item);
    if (hasOwnProperty.call(acc, index)) {
      acc[index].push(item);
    } else {
      baseAssignValue(acc, index, [item]);
    }
    return acc;
  }, {});
};

export function sentenceCase(str) {
  str || (str = '');
  return str
    .replace(/([A-Z])/g, (_, match) => ' ' + match.toLowerCase())
    .replace(/[_\- ]+(.)/g, ' $1')
    .trim();
}

export function camelCase(str) {
  return sentenceCase(str).replace(/\s(.)/g, (_, match) => match.toUpperCase());
}

export function kebabCase(str) {
  return sentenceCase(str).replace(/[ ]/g, '-');
}
export const matchMenuRoutes = path => {
  if (path.length < 4) return false;
  let [a, b] = [];
  path.forEach(key => {
    switch (key) {
      case 'flights':
        a = true;
        break;
      case 'arrivals':
        b = true;
        break;
      case 'departures':
        b = true;
        break;
      default:
        break;
    }
  });
  return a && b;
};

export function goBackToPrevPage(props, oldUrl) {
  if (!props || !props.location || !props.history) {
    (window || document).location.replace('/');
    return;
  }
  const { pathname } = props.location;

  props.history.replace({
    pathname: pathname.replace(`/${oldUrl}`, ''),
  });
}
